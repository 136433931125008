<template>
  <div>
    <!--    首页轮播图-->
    <div class="content7">
      <el-carousel :interval="2000" type="card" width="1980px" height="600px">
        <el-carousel-item v-for="(item, index) in png" :key="index">
          <img class="medium" :src="item.img" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!--    核心-->
    <div class="content8">
      <div class="img">
        <div style="margin-left: 370px">
          <span class="center1">包容 </span>
          <span class="center2">创新</span>
          <span class="center3">超越</span>
          <span class="center4">共享</span>
        </div>
      </div>
    </div>
    <div class="content1">
      <div class="top">
        <div class="title">企业服务</div>
        <div class="line"></div>

      </div>
      <div class="bom">
        <div class="substance">
          <div class="title1">
          骐骥科技的功能优势
          </div>
          <div class="subject">
            <div class="words">
            公司秉承“客户为尊、服务为荣、创意为先、技术为实”的经营理念，恪守“共创、共享、共赢”的企业精神，奉行“知人善任、人尽其才”的人才理念，通过开放式创新、卓越运营管理、人力资源发展等战略的实施，以一流的服务、一流的技术和一流的产品，全面打造公司的核心竞争力，成就最优秀的行业品牌，提供最有价值的行业服务。<br/>
             <strong>1.细致的谈判</strong>，通过与客户合作谈判、制定协议、制定商务解决方案，来促进商务运作标准的优化，并进行日常客户商务工作的处理。<br/>
             <strong>2.精准的报价</strong>，搜集行业和市场信息并进行分析整理，为客户报出合理优惠的价格。<br/>
              <strong>3.专业的服务</strong>，根据客户提供的信息，作出专业的诊断分析，并制定相应的专业咨询方案。<br/>
              <strong>4.优质的团队</strong>，我们的商务人员全部是从业15年以上的资深人员，能够为客户提供全方位的项目咨询建议。<br/>
            </div>
            <div class="title2">推荐服务</div>
            <div class="details">
              <span>Android开发</span>
              <span>IOS开发</span>
              <span>HTML5开发</span>
              <span>微信公众平台开发</span>
               <span>微信小程序</span>
              <span>手机网站建设</span>
            </div>
            <!--            <div class="details2"><a href="https://www.baidu.com/">了解详情</a></div>-->
            <div class="title3">合作伙伴</div>
            <div class="details">
              <span>日立</span>
              <span>嘉科</span>
              <span>lenovo 联想</span>
              <span>SONY.</span>
              <span>Changhong长虹</span>
              <span>海底捞</span>
            </div>
          </div>
          <div class="title1 mg">公司团建</div>
          <div class="subject2">
            <el-carousel height="180px" indicator-position="outside">
              <el-carousel-item v-for="item in 4" :key="item" class="Rotation">
                <div class="list" style="margin-right: 10px">
                  <div >
                    
                   <img style="height:207px;width:349px;" src="../assets/img/build/2.jpg" alt="" />
                  </div>
                </div>
                <div class="list" style="margin-right: 10px">
                  <div >
                    <img style="height:207px;width:349px" src="../assets/img/build/3.jpg" alt="" />
                  </div>
                </div>
                <div class="list" style="margin-right: 10px">
                  <div>
                    <img style="height:207px;width:349px" src="../assets/img/build/4.jpg" alt="" />
                  </div>

                </div>
                <div class="list">
                  <div>
                    <img style="height:207px;width:349px" src="../assets/img/build/5.jpg" alt="" />
                  </div>

                </div>
               
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post } from "../utils/http.js";
import config from "../config/index";

export default {
  components: {},
  props: {},
  data() {
    return {
      png: [
        { img: require("../assets/img/index/chanye.png") },
        { img: require("../assets/img/index/02index.png") },
        { img: require("../assets/img/index/chanye3.png") },
      ],
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  created() {
    this.lode();
  },
  methods: {
    lode() {
      let data = {
        //请求参数
        username: "t1",
        password: "abc123..",
        code: "zjqj",
      };
      let url = "/login";
      post(url, data).then((res) => {
        localStorage.setItem("token", res.token);
        this.ceshi();
      });
    },
    ceshi() {
      let url = "/portal/company/6";
      get(url, null).then((res) => {
        this.data = res.data.company;
        this.datalist = res.data.developments;
      });
      let activityurl = "/portal/activity/list";
      get(activityurl, null).then((res) => {
        this.activity = res.rows;
        for (let i = 0; i < this.activity.length; i++) {
          this.activity[i].img = config.imageUrl + this.activity[i].img;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.top {
  
  text-align: center;
  margin-top: 30px;

  .title {
    color: #000028;
    font-size: 24px;
  }

  .line {
    width: 40px;
    height: 1px;
    background: #ef8200;
    opacity: 1;
    margin: 0 auto;
    margin-top: 8px;
    margin-bottom: 7px;
  }

  .title1 {
    font-size: 14px;
    color: #999999;
  }
}

.content {
  width: 1900px;
  height: 860px;
  margin: 0 auto;
  padding-top: 70px;
  border-radius: 10px !important;

  .right {
    width: 1900px;
    height: 860px;

    /deep/ .ivu-carousel-list {
      width: 1900px;
      height: 860px;
      border-radius: 10px !important;
    }

    .swiper2 {
      width: 1900px;
      height: 860px;
      border-radius: 10px !important;
    }
  }
}

.content1 {
  width: 100%;
  padding-top: 70px;
  background-color: #f5f6f7;
  .el-carousel__item {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    height: 207px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: rgba(211, 220, 230, 0.98);
  }
  .bom {
    margin-top: 25px;

    .substance {
      width: 1427px;
      height: 900px;
      margin: 0 auto;

      .title1 {
        padding-left: 35px;
        border-left: 4px solid #0f8cee;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 2px;
        margin-bottom: 20px;
      }

      .mg {
        margin-top: 40px;
      }

      .subject {
        background-color: #ffffff;
        padding: 25px 40px 40px;
        .words {
          font-size: 14px;
          line-height: 24px;
          color: #666666;
          overflow: hidden;
          letter-spacing: 1px;
        }

        .title2 {
          margin-top: 40px;
          font-size: 16px;
          letter-spacing: 1px;
          line-height: 16px;
          border-left: 3px solid #0f8cee;
          padding-left: 8px;
          font-weight: bold;
        }

        .title3 {
          margin-top: 60px;
          font-size: 16px;
          letter-spacing: 1px;
          line-height: 16px;
          border-left: 3px solid #0f8cee;
          padding-left: 8px;
          font-weight: bold;
        }

        .details {
          margin-top: 20px;

          span {
            line-height: 28px;
            text-align: center;
            display: inline-block;
            padding: 0 10px;
            height: 30px;
            width: 158px;
            overflow: hidden;
            color: #999;
            border: 1px solid #dfe1e6;
            font-size: 14px;
            letter-spacing: 1px;
            margin: 0px 10px 10px 0px;
          }
        }

        .details2 {
          margin-top: 10px;
          margin-bottom: 20px;

          a {
            text-align: center;
            display: inline-block;
            height: 40px;
            width: 150px;
            font-size: 14px;
            letter-spacing: 1px;
            background: #108cee;
            line-height: 40px;
            color: #fff;
            -webkit-transition: all 250ms cubic-bezier(0.02, 0.01, 0.2, 1);
            transition: all 250ms cubic-bezier(0.02, 0.01, 0.2, 1);
          }

          a:hover {
            box-shadow: 0 0.16px 0.1px 0 rgba(48, 55, 66, 0.15);
            transform: translate(0, -5px);
            transition-delay: 0s !important;
          }
        }

        .details3 {
          margin-top: 20px;

          span {
            display: inline-block;
            height: 60px;
            width: 158px;
            border: 1px solid #dfe1e6;
            position: relative;
            margin-right: 10px;

            img {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
      .subject2 {
        background-color: #ffffff;
        .Rotation {
          background-color: #f5f6f7;

          .list {
            width: 349px;
            height: 100%;
            display: inline-block;
            background-color: #ffffff;
            -webkit-transition: all 250ms cubic-bezier(0.02, 0.01, 0.2, 1);
            transition: all 250ms cubic-bezier(0.02, 0.01, 0.2, 1);
            .list_div {
              margin: 25px 40px 0;
              .list_div_1 {
                margin: 10px 0;
                span {
                  line-height: 18px;
                  color: #999999;
                  height: 20px;
                  margin-right: 6px;
                  font-size: 12px;
                  display: inline-block;
                  padding: 0px 7px;
                  min-width: 74px;
                  text-align: center;
                  border: 1px solid #bbbbbb;
                }
              }
              strong {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                color: #000028;
                display: block;
                height: 24px;
                overflow: hidden;
              }
              p {
                font-size: 14px;
                line-height: 24px;
                color: #666666;
                height: 48px;
                overflow: hidden;
                margin: 3px 0px 14px;
              }
              span {
                font-size: 14px;
                color: #108cee;
                line-height: 22px;
              }
            }
          }
          .list:hover {
            box-shadow: 0 16px 10px 0 rgba(48, 55, 66, 0.15);
            transform: translate(0, -5px);
            transition-delay: 0s !important;
          }
        }
      }
    }
  }
}

.content7 {
  width: 1900px;
  height: 600px;
  margin: 0 auto;
  padding-top: 100px;

  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }

  .medium {
    width: 960px;
    height: 600px;
    object-fit: cover;
  }

  .right {
    width: 1600px;
    height: 720px;

    /deep/ .ivu-carousel-list {
      width: 1600px;
      height: 720px;
      border-radius: 10px !important;
    }

    .swiper2 {
      width: 1600px;
      height: 720px;
      border-radius: 10px !important;
    }
  }
}

.content8 {
  width: 1900px;
  height: 240px;
  margin: 0 auto 60px;

  .img {
    width: 1900px;
    height: 500px;
    position: absolute;
    background: url("../assets/img/index/slogan.png") no-repeat;
    background-size: 1900px 300px;
    font-size: 80px;
    font-weight: 500;
    font-family: 华文新魏;

    .center1 {
      position: relative;
      top: 130px;
      left: 100px;
    }

    .center2 {
      position: relative;
      top: 130px;
      left: 200px;
    }

    .center3 {
      position: relative;
      top: 130px;
      left: 300px;
    }

    .center4 {
      position: relative;
      top: 130px;
      left: 400px;
    }
  }
}
</style>
